.block-container {
  padding: 20px;
}

.header-text-container {
  display: flex;
  justify-content: center;
  margin: 10px;
  font-size: 30px;
  border-radius: 10px;
}

.header-text {
  padding: 5px;
  color: white;
}

.sub-header-text-container {
  margin-bottom: 10px;
}

.sub-header-text {
  font-size: 150%;
  color: #73207a;
  font-family: ;
}

.demo-infinite-container {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow: auto;
  padding: 8px 24px;
  height: 50vh;
  background: white;
}

.deadline-infinite-container {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow: auto;
  padding: 8px 24px;
  height: 25vh;
  background: white;
}

.ant-card-bordered {
  background: transparent !important;
  border: 0 !important;
}

.custom-divider {
  height: 2px !important;
  background-color: rgb(115, 32, 122) !important;
}
